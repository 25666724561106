import React, { Component } from 'react';
import Link from '../components/link';
import Isvg from 'react-inlinesvg';
import Page from '../containers/page';
import Slider from '../components/slider';
import { Redirect } from 'react-router-dom';



import {
    Container,
    Row,
    Col,
    Carousel,
    CarouselItem,
    CarouselControl,
    CarouselIndicators,
    CarouselCaption,
} from 'reactstrap';


import weather from '../assets/weather.svg';
import evidency from '../assets/evidency.png';
import sugestije from '../assets/sugestije.png';
import app from '../assets/app.png';
import b1 from '../assets/b1.png';

import details_naslovna from '../assets/details-naslovna.jpg';


import help_icon from '../assets/help-icon.svg';
import warning_icon from '../assets/warning-icon.svg';
import mail_icon from '../assets/mail-icon.svg';
import chart_icon from '../assets/chart-icon.svg';
import star from '../assets/star.svg';
import like from '../assets/like.svg';
import shape1 from '../assets/svg/shape-1.svg';
import shape2 from '../assets/svg/shape-2.svg';

import close_ico from '../assets/svg/close-ico.svg';

import book_1 from '../assets/book-1.png';
import team_member from '../assets/team-member.png';
import moment from 'moment';

import RightSidebar from '../components/rightSidebar';
import { API_ENDPOINT } from '../constants';


class BlogDetailPage extends Component {
    constructor(props) {
        super(props);

        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
        this.goToIndex = this.goToIndex.bind(this);
        this.onExiting = this.onExiting.bind(this);
        this.onExited = this.onExited.bind(this);
        this.state = {
            gallery: [],
            activeIndex: 0,
            ...props.initialData
        };
    }

    componentDidMount() {

        if (typeof window !== 'undefined') { window.scrollTo(0, 0); }
        this.get()



    }

    get = () => {


        fetch(`https://interni-api.euroexpress.ba/log/new`, {
            method: 'POST',
            headers: {
                'content-type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            },
            body: JSON.stringify({
                path: this.props[0].location.pathname,
            })
        }).then(res => res.json()).then((result) => {

        })
        fetch(`${API_ENDPOINT}/home`, {
            method: 'GET',
            headers: {
                'content-type': 'application/json',
            },
        }).then(res => res.json()).then((result) => {
            this.setState({home: result})
        })

        const folderToken = sessionStorage.getItem('folderToken');
        if (folderToken) {
            fetch(`${API_ENDPOINT}/folders/verify/${this.props[0].match.params.folder}/${this.props[0].match.params.category}/${this.props[0].match.params.submenu}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    folderToken: folderToken
                })
            })
                .then(res => {

                    if (res.status === 401) {
                        throw new Error('Unauthorized');
                    }
                    if (res.status !== 200) {
                        throw new Error(`Unexpected status code: ${res.status}`);
                    }
                })
                .then(() => {
                    //then

                    return fetch(`${API_ENDPOINT}/articles/folder/${this.props[0].match.params.folder}/${this.props[0].match.params.category}/${this.props[0].match.params.submenu}/${this.props[0].match.params.alias}`, {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                    });
                })
                .then(res => res.json())
                .then(result => {

                    result.item.dateTS = new Date(result.item.date + " " + result.item.time).getTime() / 1000;
                    if (result && result.item && result.item.gallery) {
                        result.item.galleryLength = result.item.gallery.length;
                    }

                    if (result && result.item && result.item.shortDescription) {
                        if (result.item.shortDescription.split(',')[0] == 'Drage koleginice i kolege' || result.item.shortDescription.split(',')[0] == 'Drage koleginice' || result.item.shortDescription.split(',')[0] == 'Drage kolege') {
                            result.item.checkIntro = true;
                        }
                    }
                    this.setState({
                        data: result.item,
                        gallery: result.item.gallery ? result.item.gallery : [],
                    })

                })
                .catch(error => {
                    if (error.message === 'Unauthorized') {
                        const currentPath = typeof window !== 'undefined' ? window.location.pathname : '';
                        const pathSegments = currentPath.split('/').filter(segment => segment);
                        const newPath = `/${pathSegments.slice(0, -3).join('/')}`;
                        this.props[0].history.push(newPath);
                    } else {
                        console.error('Error:', error);
                    }
                });
        }else{
            const currentPath = typeof window !== 'undefined' ? window.location.pathname : '';
            const pathSegments = currentPath.split('/').filter(segment => segment);
            const newPath = `/${pathSegments.slice(0, -3).join('/')}`;
            this.props[0].history.push(newPath);
        }
    }
    componentDidUpdate(prevProps) {
        if (prevProps[0].location.pathname != this.props[0].location.pathname) {
            if (typeof window !== 'undefined') { window.scrollTo(0, 0); }
            this.get()


            for (let i = 0; i < this.props.loadData.length; i++) {
                this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match).then((data) => {
                    this.setState({
                        ...data
                    })
                })
            }
        }



    }

    onExiting() {
        this.animating = true;
    }

    onExited() {
        this.animating = false;
    }

    next() {
        if (this.animating) return;
        const nextIndex = this.state.activeIndex === this.state.gallery.length - 1 ? 0 : this.state.activeIndex + 1;
        this.setState({ activeIndex: nextIndex });
    }

    previous() {
        if (this.animating) return;
        const nextIndex = this.state.activeIndex === 0 ? this.state.gallery.length - 1 : this.state.activeIndex - 1;
        this.setState({ activeIndex: nextIndex });
    }

    goToIndex(newIndex) {
        if (this.animating) return;
        this.setState({ activeIndex: newIndex });
    }



    render() {
        const { activeIndex } = this.state;
        const slides = this.state.gallery && this.state.gallery.map((item) => {
            return (
                <CarouselItem
                    tag="div"
                    key={item}
                    onExiting={this.onExiting}
                    onExited={this.onExited}
                >
                    <div className="lightbox-item">
                        <img src={API_ENDPOINT + item} />
                    </div>

                </CarouselItem>
            );
        });
        return (
            <div>
                {
                    typeof localStorage !== 'undefined' && !localStorage.token ? <Redirect to='/prijava' /> : null
                }
                <img src={shape1} className="shape-1" />
                <img src={shape2} className="shape-2" />
                <section id="content">
                    <Container>
                        <Row>
                            <Col lg="9" className="nop">
                                <div className="content l-0 r-fix pd0">
                                    <Col lg="12">
                                        <div className="content-container">
                                            <article className="article-details">
                                                <a className="category">{this.state.data && this.state.data.categoryName}</a>
                                                <h1>{this.state.data && this.state.data.name}</h1>
                                                <span className="date">{this.state.data && this.state.data.dateTS ? this.props.translateMonthBlog(this.state.data.dateTS) : null}</span>
                                                {
                                                    this.state.data && this.state.data.shortDescription && this.state.data.checkIntro ?
                                                        <>
                                                            <p>{this.state.data.shortDescription.split(",")[0] + ","}
                                                                <br /><br />
                                                                {this.state.data.shortDescription.slice(this.state.data.shortDescription.split(",")[0].length + 1)}</p>
                                                        </>
                                                        : this.state.data && this.state.data.shortDescription && !this.state.data.checkIntro ?
                                                            <p>{this.state.data.shortDescription}</p>
                                                            :
                                                            null

                                                }

                                                <div className="article-datails-image">
                                                    {
                                                        this.state.data ?
                                                            <img src={API_ENDPOINT + this.state.data.image} alt="EuroExpress" />
                                                            :
                                                            null
                                                    }
                                                </div>

                                            
                                                {
                                                    this.state.data ?
                                                        <div dangerouslySetInnerHTML={{ __html: this.state.data.content }}></div>
                                                        :
                                                        null
                                                }

                                                {
                                                    this.state.gallery && this.state.data && this.state.data.galleryLength > 0 ?

                                                        <div className="foto-gallery-wrap">

                                                            <h3>Galerija slika:</h3>
                                                            <div className="foto-gallery">
                                                                {
                                                                    this.state.gallery && this.state.gallery.map((item, idx) => {
                                                                        return (
                                                                            idx == 0 ?
                                                                                <div className="gallery-image1" onClick={() => {
                                                                                    let index = idx;
                                                                                    this.setState({ lightbox: true, activeIndex: index })
                                                                                }}
                                                                                    key={idx}>
                                                                                    <img src={API_ENDPOINT + item} />
                                                                                </div>
                                                                                :
                                                                                null

                                                                        )
                                                                    })
                                                                }
                                                                <div className="gallery-image2-wrap">
                                                                    {
                                                                        typeof window != 'undefined' && window.innerWidth > 768 && this.state.gallery && this.state.gallery.map((item, idx) => {
                                                                            return (
                                                                                idx > 0 && idx < 6 ?
                                                                                    <div className={idx == 5 && this.state.data.galleryLength > 6 ? "gallery-image2 overlay" : "gallery-image2"} onClick={() => {
                                                                                        let index = idx;
                                                                                        this.setState({ lightbox: true, activeIndex: index })
                                                                                    }} key={idx}>
                                                                                        <img src={API_ENDPOINT + item} />
                                                                                        <div className="other-images">
                                                                                            {
                                                                                                idx == 5 && this.state.data.galleryLength > 6 ?
                                                                                                    <h4>+{this.state.data.galleryLength - 6}</h4>
                                                                                                    :
                                                                                                    null
                                                                                            }
                                                                                        </div>
                                                                                    </div>
                                                                                    :
                                                                                    null

                                                                            )
                                                                        })
                                                                    }
                                                                    {
                                                                        typeof window != 'undefined' && window.innerWidth <= 768 && this.state.gallery && this.state.gallery.map((item, idx) => {
                                                                            return (
                                                                                idx > 0 && idx < 4 ?
                                                                                    <div className={idx == 3 && this.state.data.galleryLength > 4 ? "gallery-image3 overlay" : "gallery-image3"} onClick={() => {
                                                                                        let index = idx;
                                                                                        this.setState({ lightbox: true, activeIndex: index })
                                                                                    }} key={idx}>
                                                                                        <img src={API_ENDPOINT + item} />
                                                                                        <div className="other-images">
                                                                                            {
                                                                                                idx == 3 && this.state.data.galleryLength > 4 ?
                                                                                                    <h4>+{this.state.data.galleryLength - 4}</h4>
                                                                                                    :
                                                                                                    null
                                                                                            }
                                                                                        </div>
                                                                                    </div>
                                                                                    :
                                                                                    null

                                                                            )
                                                                        })
                                                                    }
                                                                </div>
                                                            </div>

                                                        </div>

                                                        :
                                                        null
                                                }

                                            </article>
                                        </div>
                                    </Col>
                                    <Col lg="12">
                                        <Row className="similar-articles mb-25">
                                            <Col lg="12">
                                                <div className="other-news-title">
                                                            <div className="flex">
                                                                <Link to={`/${this.props[0].match.params.category}/novosti/${this.props[0].match.params.submenu}/folder/${this.props[0].match.params.folder}`}>Nazad</Link>
                                                            </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                </div>
                            </Col>
                            <Col lg="3" className="nop">
                                {
                                    this.state.home && this.state.home.statistics ?
                                        <RightSidebar
                                            totalVehicles={this.state.home.statistics.totalVehicles}
                                            newMembers={this.state.home.statistics.newMembers}
                                            deliveryLines={this.state.home.statistics.deliveryLines}
                                            totalEmployess={this.state.home.statistics.totalEmployess}
                                            warehouses={this.state.home.statistics.warehouses}
                                            shops={this.state.home.statistics.shops}
                                            googlePlayLink={this.state.home.googlePlayLink}
                                            appStoreLink={this.state.home.appStoreLink}
                                        />
                                        :
                                        null
                                }
                            </Col>
                        </Row>
                    </Container>
                    {this.state.lightbox ?
                        <div className="lightbox">
                            <div className="close" onClick={() => this.setState({ lightbox: null })}>
                                <Isvg src={close_ico} />
                            </div>
                            <div className="photo-num">
                                <h5>{Number(this.state.activeIndex) + 1} / {this.state.data.galleryLength}</h5>
                            </div>

                            <Carousel
                                activeIndex={activeIndex}
                                next={this.next}
                                previous={this.previous}
                                autoPlay={null}
                            >
                                {slides}
                                <CarouselControl direction="prev" directionText="Previous" onClickHandler={this.previous} />
                                <CarouselControl direction="next" directionText="Next" onClickHandler={this.next} />
                            </Carousel>

                        </div>
                        : null
                    }
                </section>

            </div>
        );
    }
}

export default Page(BlogDetailPage);