

import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import Routes from './routes'
import { Provider } from 'react-redux'
import { createStore, combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form'

import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import { langs } from './langs';
import { withRouter } from 'react-router-dom';
import DocumentMeta from 'react-document-meta';
import ReactGA from 'react-ga';
import socketIO from 'socket.io-client';
import {API_ENDPOINT} from "./constants";
// baloons import
// import bijeliLogo from '../src/assets/bijeli-logo.png'
// import bijeliVjetar from '../src/assets/bijeli-vjetar.png'
// import crveniLogo from '../src/assets/crveni-logo.png'
// import crveniVjetar from '../src/assets/crveni-vjetar.png'
// import plaviLogo from '../src/assets/plavi-logo.png'
// import plaviVjetar from '../src/assets/plavi-vjetar.png'
// const baloni = [bijeliLogo, bijeliVjetar, crveniLogo, crveniVjetar, plaviLogo, plaviVjetar]




import moment from 'moment';

const rootReducer = combineReducers({
  form: formReducer
});

const store = createStore(rootReducer)



function generateAlias(str) {
  str = str.toLowerCase();
  str = str.replace(/ä/g, 'a');
  str = str.replace(/ö/g, 'o');
  str = str.replace(/ü/g, 'u');
  str = str.replace(/ß/g, 'b');

  str = str.replace(/[^a-zA-Z0-9]/gi, '-').toLowerCase()
  str = str.replace(/-+/g, '-');

  return str;
}

if (String.prototype.generateAlias == null) {
  String.prototype.generateAlias = function () {
    return generateAlias(this);
  }
}


Object.translate = function (o, s, lang) {
  if (!o) {
    return '';
  }

  s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
  s = s.replace(/^\./, '');           // strip a leading dot
  var a = s.split('.');
  for (var i = 0, n = a.length; i < n; ++i) {
    var k = a[i];
    if (k in o) {
      o = o[k];
    } else {
      return;
    }
  }
  return o[lang] ? o[lang] : o['ba'];
}


Object.get = function (o, s) {
  console.log(o, s)
  if (!o) {
    return null;
  }

  s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
  s = s.replace(/^\./, '');           // strip a leading dot
  var a = s.split('.');
  for (var i = 0, n = a.length; i < n; ++i) {
    var k = a[i];
    if (k in o) {
      o = o[k];
    } else {
      return;
    }
  }
  return o;
}


if (String.prototype.translate == null) {
  String.prototype.translate = function (lang) {

    /* if (!localStorage.translate){
       localStorage.translate = JSON.stringify({
         'ba': {
  
         },
         'en': {
  
         }
       });
     }
  
     let obj = JSON.parse(localStorage.translate);
     obj.en[this] = this;
     obj.ba[this] = this;
 
     localStorage.translate = JSON.stringify(obj);
     
     return this;*/

    if (langs[lang] && langs[lang][this])
      return langs[lang][this];
    else return this;
  }
}



class App extends Component {
  constructor(props) {
    super(props);
    this.googleMapsCallback = this.googleMapsCallback.bind(this);
    this.translate = this.translate.bind(this);
    this.setLang = this.setLang.bind(this);
    this.setLightMode = this.setLightMode.bind(this);

    // this.getRandomStyles = this.getRandomStyles.bind(this);
    // this.createBaloon = this.createBaloon.bind(this);
    // this.removeBalloons = this.removeBalloons.bind(this);

    let lang = 'ba';
    if (typeof window !== 'undefined') {

      window.googleMapsCallback = this.googleMapsCallback;

      if (props.location.pathname.indexOf('/en') !== -1) {
        lang = 'en';
      }


    } else {
      lang = this.props.lang;
    }
    this.state = {
      _googleMapsLoaded: false,
      lang: lang,
      lightMode: 0,
      services: [],
      latestNews: [],
      ...props.appInitialData

    };

  }

  setLang(lang) {
    this.setState({
      lang: lang
    });
  }
  setLightMode(val) {
    this.setState({
      lightMode: val
    });
  }
  updateMeta = (data) => {
    this.setState({
      metaTags: data
    })
  }



  translate(text) {
    return text;
  }

  translateMonthBlog = (date) => {
    let dateString = moment.unix(date).format('DD.MMMM.YYYY.');
    let month = dateString.split(".")[1];
    let monthTranslate = '';
    if (month == 'January') {
      monthTranslate = 'Januar';
    } else if (month == 'February') {
      monthTranslate = 'Februar';
    } else if (month == 'March') {
      monthTranslate = 'Mart';
    } else if (month == 'April') {
      monthTranslate = 'April';
    } else if (month == 'May') {
      monthTranslate = 'Maj';
    } else if (month == 'June') {
      monthTranslate = 'Jun';
    } else if (month == 'July') {
      monthTranslate = 'Jul';
    } else if (month == 'August') {
      monthTranslate = 'Avgust';
    } else if (month == 'September') {
      monthTranslate = 'Septembar';
    } else if (month == 'October') {
      monthTranslate = 'Oktobar';
    } else if (month == 'November') {
      monthTranslate = 'Novembar';
    } else {
      monthTranslate = 'Decembar';
    }
    let day = dateString.split(".")[0];
    let year = dateString.split(".")[2];
    return day + ". " + monthTranslate + ", " + year + ".";
  }

  formatPrice = (price = 0) => {
    if (price) {
      price = price.replace(/,/g, '.').replace(/[^0-9.]/g, '');
    }
    price = Number(price)
    const formattedPrice = price.toFixed(2).replace(".", ",") + " KM";
    return formattedPrice;
  }

  render() {
    let meta;

    if (this.state.metaTags) {
      meta = {
        title: this.state.metaTags.title,
        description: this.state.metaTags.description ? this.state.metaTags.description : null,
        meta: {
          charset: 'utf-8',
          name: {
            'og:title': this.state.metaTags.title,
            'og:image': this.state.metaTags['og:image'] ? this.state.metaTags['og:image'] : null,
            'og:description': this.state.metaTags.description ? this.state.metaTags.description : null
          }
        }
      };
    }

    return (
      <Provider store={store}>
        {this.state.metaTags ? <DocumentMeta {...meta} /> : null}
        <Routes
          {...this.state}
          translate={this.translate}
          setLang={this.setLang}
          setLightMode={this.setLightMode}
          serverFetch={this.props.serverFetch}
          initialData={this.props.initialData ? this.props.initialData : {}}
          updateMeta={this.updateMeta}
          translateMonthBlog={this.translateMonthBlog}
          formatPrice={this.formatPrice}
          allowCookies={() => {
            localStorage.allowCookies = true;
            this.setState({
              cookies: true
            });
          }}
          verifyUser={this.verifyUser}
        />
      </Provider>

    );

  }

  verifyUser = () => {
    if (typeof localStorage !== 'undefined' && localStorage.token) {
      fetch(`${API_ENDPOINT}/user`, {
        method: 'GET',
        headers: {
          'content-type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      }).then(res => res.json()).then((result) => {
        // console.log(result);
        // this.setState({
        //   user: result
        // })
        if (!result.error) {
          this.setState({
            user: result
          })
        } else {
          if (typeof window != 'undefined') {
            localStorage.removeItem('token')
          }
        }
      })

    }
  }

  // ----------------- baloons -----------------

  //   balloonContainer = ''


  //   removeBalloons() {
  //     if (this.balloonContainer) {
  //       this.balloonContainer.style.opacity = 0;
  //       setTimeout(() => {
  //         this.setState({ balloonContainerDisabled: true })
  //         this.balloonContainer.remove()
  //       }, 500)
  //     }

  //   }


  //   getRandomStyles() {
  //     var dur = this.random(5) + 17;
  //     var ml = this.random(240);
  //     var mt = this.random(200);
  //     return `
  //   margin: ${mt}px 0 0 ${ml}px;
  // animation: float ${dur}s ease-in infinite
  // `;
  //   }

  //   random(num) {
  //     return Math.floor(Math.random() * num);
  //   }

  //   createBaloon(num) {
  //     for (var i = num; i > 0; i--) {
  //       var balloon = document.createElement("img");
  //       balloon.className = "balloon";
  //       balloon.src = baloni[this.random(6)];
  //       balloon.style.cssText = this.getRandomStyles();
  //       if (this.balloonContainer)
  //         this.balloonContainer.append(balloon);
  //       // console.log("funkcija pozvana jednom", i)
  //     }
  //   }

  // ----------------- baloons end -----------------



  componentDidMount() {
    // this.balloonContainer = document.getElementById("balloon-container");

    this.verifyUser();




    if (localStorage.allowCookies) {
      this.setState({
        cookies: true
      });
    }

    //ReactGA.initialize('UA-75168242-1');

    this.props.history.listen((location, action) => {
      let lang = 'ba';
      if (location.pathname.indexOf('/en') !== -1) {
        lang = 'en';
      } else if (location.pathname.indexOf('/sr') !== -1) {
        lang = 'sr';
      }

      // if (this.state.lang != lang) {
      //   this.setState({ lang: lang }, () => {
      //     fetch('https://interni-api.euroexpress.ba/seo/' + this.state.lang, {
      //       method: 'POST',
      //       headers: {
      //         'Content-Type': 'application/json'
      //       },
      //       body: JSON.stringify({ url: location.pathname })
      //     }).then((res) => res.json()).then((result) => {

      //       this.setState({
      //         metaTags: result
      //       })
      //     });

      //   });
      // } else {
      //   fetch('https://interni-api.euroexpress.ba/seo/' + this.state.lang, {
      //     method: 'POST',
      //     headers: {
      //       'Content-Type': 'application/json'
      //     },
      //     body: JSON.stringify({ url: location.pathname })
      //   }).then((res) => res.json()).then((result) => {

      //     this.setState({
      //       metaTags: result
      //     })
      //   });

      // }

      // ReactGA.pageview(location.pathname + location.search);
      if (window.gtag) {
        window.gtag('js', new Date());

      }

    });


    // window.addEventListener("click", () => {
    //   this.removeBalloons();
    // });

    // this.createBaloon(20)

    // setTimeout(() => {
    //   this.createBaloon(20)
    // }, [3000])

    // setTimeout(() => {
    //   this.createBaloon(20)
    // }, [5000])


  }


  componentWillUnmount() {
    // window.removeEventListener('click', this.removeBalloons, false);
  }

  googleMapsCallback() {
    console.log("true");
    this.setState({ _googleMapsLoaded: true });
  }

}

export default withRouter(App);
