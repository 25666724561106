import React, { Component } from 'react';
import Link from '../components/link';

import Isvg from 'react-inlinesvg';
import Page from '../containers/page';

import {
    Container,
    Row,
    Col,
    Input,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Carousel,
    CarouselItem,
    CarouselControl,
    CarouselIndicators,
    CarouselCaption,
    UncontrolledDropdown,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter
} from 'reactstrap';

import ok from '../assets/svg/ok.svg';


import moment from 'moment';

import { Player, Controls } from "@lottiefiles/react-lottie-player";

import no_content from '../assets/images/no_content.png';

import arrow_left from '../assets/arrow-left.svg';
import list_1 from '../assets/list-1.svg';
import list_2 from '../assets/list-2.svg';
import arrow_right from '../assets/arrow-right.svg';
import weather from '../assets/weather.svg';
import searchIcon from '../assets/search-icon.svg';
import cartIcon from '../assets/cart-icon.svg';
import app from '../assets/app.png';
import b1 from '../assets/b1.png';
import shape1 from '../assets/svg/shape-1.svg';
import shape2 from '../assets/svg/shape-2.svg';

import details_naslovna from '../assets/details-naslovna.jpg';

import ReactPaginate from 'react-paginate';
import RightSidebar from '../components/rightSidebar';
import { API_ENDPOINT } from '../constants';

import { Redirect } from 'react-router-dom';
import xIcon from '../assets/svg/x-icon.svg';
import docSvg from '../assets/svg/doc_svg.svg';


var striptags = require('striptags');

class ShopPage extends Component {
    constructor(props) {
        super(props);
        this.updateStateFromSearch = this.updateStateFromSearch.bind(this);
        this.updateParam = this.updateParam.bind(this);
        this.get = this.get.bind(this);

        this.state = {
            promotedProjects: [],
            page: 0,
            view: 0,
            shopCategories: [],
            ...props.initialData
        };
    }

    updateStateFromSearch(callback) {
        let broken = this.props[0].location.search.split('?').pop().split('&');
        let params = { page: 0 };
        for (let i = 0; i < broken.length; i++) {
            if (broken[i].indexOf('=') !== -1) {
                params[broken[i].split('=')[0]] = broken[i].split('=')[1];
            }
        }
        let setObj = {
            category: null,
            tag: null,
            items: []
        }
        if (!params.pretraga && this.state.search) {
            setObj.search = '';
        }
        this.setState(setObj, () => {
            this.setState(params, callback);

        })

    }


    updateParam(name, value) {

        let broken = this.props[0].location.search.split('?').pop().split('&');
        let params = {};
        for (let i = 0; i < broken.length; i++) {
            if (broken[i].indexOf('=') !== -1) {
                params[broken[i].split('=')[0]] = broken[i].split('=')[1];
            }
        }


        params[name] = value;

        let paramsArr = [];
        for (var key in params) {
            if (params.hasOwnProperty(key) && params[key]) {

                paramsArr.push(key + "=" + params[key]);
            }
        }




        let search = '?' + paramsArr.join('&');


        this.props[0].history.push(this.props[0].location.pathname + search);
        ////console.log(search);
    }

    updateParams(names, values) {

        let broken = this.props[0].location.search.split('?').pop().split('&');
        let params = {};
        for (let i = 0; i < broken.length; i++) {
            if (broken[i].indexOf('=') !== -1) {
                params[broken[i].split('=')[0]] = broken[i].split('=')[1];
            }
        }


        for (let i = 0; i < names.length; i++) {
            const name = names[i];
            const value = values[i];

            if (value === null) {
                delete params[name];
            } else {
                params[name] = value;
            }
        }


        let paramsArr = [];
        for (let key in params) {
            if (params.hasOwnProperty(key) && params[key]) {
                paramsArr.push(`${key}=${params[key]}`);
            }
        }

        let search = '?' + paramsArr.join('&');

        this.props[0].history.push(this.props[0].location.pathname + search);
    }


    componentDidUpdate(prevProps, prevState) {
        let params = this.getParams()
        if (prevProps[0].location.search != this.props[0].location.search) {
            this.updateStateFromSearch(() => {
                this.get()
            });
        }
        if (prevProps[0].location.pathname != this.props[0].location.pathname) {
            this.get()
            this.resetSerach()
        }

        if (!params?.kategorija && this.state.shopCategory) {
            this.setState({ shopCategory: null })
        }
    }

    componentDidMount() {

        if (typeof window !== 'undefined') { window.scrollTo(0, 0); }


        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match).then((data) => {
                this.setState({
                    ...data
                })
            })
        }


        fetch(`https://interni-api.euroexpress.ba/log/new`, {
            method: 'POST',
            headers: {
                'content-type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            },
            body: JSON.stringify({
                path: this.props[0].location.pathname,
            })
        }).then(res => res.json()).then((result) => {

        })




        this.updateStateFromSearch(this.get);



    }
    resetSerach = () => {
        this.setState({ search: null }, () => {
            this.updateParam('pretraga', null);
        });
    }
    getParams = () => {

        let broken = this.props[0].location.search.split('?').pop().split('&');
        let params = {};
        for (let i = 0; i < broken.length; i++) {
            if (broken[i].indexOf('=') !== -1) {
                params[broken[i].split('=')[0]] = broken[i].split('=')[1];
            }
        }

        return params;

    }
    get() {
        let params = this.getParams()
        let shopCategory = this.state.shopCategory?._id;
        if (!shopCategory && params.kategorija) {
            shopCategory = params.kategorija;
        }


        let search;
        if (params && params.pretraga) {
            search = params.pretraga;
        }

        fetch(`https://interni-api.euroexpress.ba/shop/articles/all`, {
            method: 'POST',
            headers: {
                'content-type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            },
            body: JSON.stringify({
                page: this.state.page,
                lang: this.props.lang,
                limit: (!shopCategory && !search) ? 9 : 12,
                shopCategory: shopCategory,
                search: search
            })
        }).then(res => res.json()).then((result) => {

            this.setState({
                items: result.items,
                total: result.total
            })
        })

        fetch(`https://interni-api.euroexpress.ba/shop/categories/all`, {
            method: 'POST',
            headers: {
                'content-type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            },
            body: JSON.stringify({

            })
        }).then(res => res.json()).then((result) => {
            let setObj = {
                shopCategories: result
            };

            if (params.kategorija && result?.filter(el => el._id === params.kategorija).length) {
                setObj.shopCategory = result.filter(el => el._id === params.kategorija)[0]
            }

            this.setState(setObj)
        })




    }

    searchShop = () => {
        let search = this.state.search;
        this.updateParams(['pretraga','page'], [search,null])
    }

    buyArticle = (articleId) => {
        if (articleId) {

            fetch(`https://interni-api.euroexpress.ba/shop/buy/article`, {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                },
                body: JSON.stringify({
                    article: articleId
                })
            }).then(res => res.json()).then((result) => {
                if (result) {
                    if (result.error) {
                        this.setState({ messageSent: result.error })
                    } else {
                        this.setState({ messageSent: "Vaš zahtjev je uspješno zaprimljen, obrada je u toku, bićete obavješteni." })
                        this.props.verifyUser()
                    }
                }

            })

        }
    }


    render() {
        const params = this.getParams()
        let pagination = false;

        let otherPages = false;
        const itemPerPage = (!this.state.shopCategory && !params.pretraga) ? 9 : 12;

        if (this.state.total / itemPerPage) {
            otherPages = true;
        }
        console.log(this.state.total, itemPerPage)
        if (otherPages) {
            pagination = true;
        }

        return (
            <div>
                {
                    typeof localStorage !== 'undefined' && !localStorage.token ? <Redirect to='/prijava' /> : null
                }
                <img src={shape1} className="shape-1" />
                <img src={shape2} className="shape-2" />
                <section id="content">
                    <Container>
                        <Row>
                            <Col lg="9" className="nop">
                                <div className="content l-0 r-fix pd0">
                                    <div className="content-container">
                                        <div className="news-list-title">
                                            {
                                                !this.state.shopCategory ?
                                                    <div className="title-content web-shop-title-wrap">
                                                        <h5 className="section-title">{'Interni shop'}</h5>
                                                        {/* <div className="breadcumb">
                                                    <span>
                                                        <Link to="/">Početna</Link>/
                                                        <a className="b-active">Interni shop</a>
                                                    </span>


                                                </div> */}
                                                        <div className='web-shop-search-field-wrap'>
                                                            <Input
                                                                type='text'
                                                                placeholder='Unesite pojam za pretragu...'
                                                                value={this.state.search}
                                                                onChange={(e) => {
                                                                    if (this.state.search !== e.target.value)
                                                                        this.setState({ search: e.target.value })
                                                                }}
                                                                onKeyUp={(e) => {
                                                                    if (e.key === 'Enter') {
                                                                        this.searchShop()
                                                                    }
                                                                }}

                                                            />
                                                            <div className='serach-button' onClick={() => this.searchShop()}>
                                                                <Isvg src={searchIcon} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    :
                                                    <div className="title-content web-shop-title-wrap web-shop-title-wrap-category-active">
                                                        <div className='category-info-wrap'>
                                                            {
                                                                this.state.shopCategory.icon ?
                                                                    <img src={API_ENDPOINT + this.state.shopCategory.icon} />
                                                                    :
                                                                    null
                                                            }
                                                            <h5 className="section-title">{this.state.shopCategory.name}</h5>
                                                        </div>

                                                        <div className='web-shop-search-field-wrap'>
                                                            <Input
                                                                type='text'
                                                                placeholder='Unesite pojam za pretragu...'
                                                                onChange={(e) => {
                                                                    if (this.state.search !== e.target.value)
                                                                        this.setState({ search: e.target.value })
                                                                }}
                                                                onKeyUp={(e) => {
                                                                    if (e.key === 'Enter') {
                                                                        this.searchShop()
                                                                    }
                                                                }}

                                                            />
                                                            <div className='serach-button' onClick={() => this.searchShop()}>
                                                                <Isvg src={searchIcon} />
                                                            </div>
                                                        </div>
                                                    </div>
                                            }

                                        </div>


                                        <div className='instructions-wrap'>
                                            <a href="/Uputstvo_za_kupovinu_ostecenih_artikala_putem_internog_portala.pdf" target="_blank">
                                                <div className='instructions'>
                                                    <Isvg src={docSvg} />    Uputstvo za kupovinu oštećenih artikala
                                                </div>
                                            </a>

                                        </div>


                                        <div className="content-1">
                                            {
                                                this.state.shopCategories?.length && !this.state.shopCategory && !params.pretraga ?
                                                    <Row className="nop custom-layout-for-categories-row">
                                                        {
                                                            this.state.shopCategories.map((item, idx) => {
                                                                return (
                                                                    <Col  md="3"  xs="4" className="custom-layout-for-categories-col">
                                                                        <div className="shop-categories-wrap" onClick={() => {
                                                                            this.setState({ shopCategory: item }, () => {
                                                                                this.updateParams(['page','kategorija'], [null,item._id])
                                                                                this.get()

                                                                            })
                                                                        }}>
                                                                            {
                                                                                item.icon ?
                                                                                    <div className="shop-categories-icon">
                                                                                        <img src={API_ENDPOINT + item.icon} />
                                                                                    </div>
                                                                                    :
                                                                                    null
                                                                            }
                                                                            <div className="shop-categories-name">
                                                                                {item.name}
                                                                            </div>
                                                                        </div>


                                                                    </Col>
                                                                )
                                                            })
                                                        }
                                                    </Row>
                                                    :
                                                    null

                                            }


                                            {
                                                (!this.state.items || !this.state.items.length) && !this.state.shopCategories?.length ?
                                                    <Row className="nop">
                                                        <div className="no-content" >
                                                            <Player
                                                                autoplay={true}
                                                                loop={true}
                                                                src="/lf30_editor_w4hitwma.json"
                                                                style={{ width: "60%" }}
                                                            ></Player>
                                                            <p>Stranica u izradi...</p>
                                                        </div>
                                                    </Row>
                                                    :
                                                    null
                                            }
                                            {
                                                this.state.items && this.state.items.length ?
                                                    <Row className="nop">
                                                        <Col lg="12">
                                                            <div className='new-articles-title'>
                                                                <h5>{!params.pretraga && !this.state.shopCategory ? 'Novi artikli' : params.pretraga ? `Artikli: ${params.pretraga}` : null}</h5>
                                                            </div>
                                                        </Col>
                                                        {
                                                            this.state.items.map((item, idx) => {
                                                                let showBuyButton = item && item.showBuyButton;

                                                                // let showBuyButton = false;
                                                                // let hours48 = 60 * 60 * 48;
                                                                // if (item && item.activeTs && (Math.floor(new Date().getTime() / 1000) - item.activeTs) > hours48) {
                                                                //     showBuyButton = true;
                                                                // }
                                                                return (
                                                                    <Col lg="4" className="shop-article-wrap">
                                                                        <div className="shop-article" onClick={() => {
                                                                            this.props[0].history.push(`/interni-shop/${item.categoryLink}/${item._id}`)
                                                                        }}>
                                                                            <div className="shop-article-image">
                                                                                <img src={API_ENDPOINT + (item.images && item.images[0])} />
                                                                            </div>
                                                                            <div className="shop-article-category">
                                                                                {item.categoryName}
                                                                            </div>
                                                                            <div className="shop-article-name">
                                                                                {typeof window != 'undefined' && window.innerWidth < 1024 ?
                                                                                    item.name.length > 35 ?
                                                                                        <h3>{item.name.substring(0, 35)}...</h3>
                                                                                        :
                                                                                        <h3>{item.name}</h3>
                                                                                    :
                                                                                    item.name.length > 35 ?
                                                                                        <h3>{item.name.substring(0, 35)}...</h3>
                                                                                        :
                                                                                        <h3>{item.name}</h3>
                                                                                }
                                                                            </div>
                                                                            <div className="shop-article-price">
                                                                                {this.props.formatPrice(item.price)}
                                                                            </div>
                                                                            <div className="shop-article-buy">
                                                                                <button disabled={!showBuyButton} className={!showBuyButton ? 'hide-button' : ''} onClick={(e) => {
                                                                                    if (showBuyButton) {
                                                                                        e.stopPropagation()
                                                                                        this.buyArticle(item._id)
                                                                                    }
                                                                                }}>
                                                                                    <Isvg src={cartIcon} />
                                                                                    Kupi sada
                                                                                </button>
                                                                            </div>

                                                                        </div>

                                                                    </Col>
                                                                )
                                                            })
                                                        }
                                                    </Row>
                                                    :
                                                    null
                                            }




                                            {
                                                pagination ?
                                                    <Row>
                                                        <Col lg="12">
                                                            <ReactPaginate
                                                                previousLabel={''.translate(this.props.lang)}
                                                                nextLabel={''.translate(this.props.lang)}
                                                                breakLabel={'...'}
                                                                breakClassName={'break-me'}
                                                                pageCount={this.state.total / itemPerPage}
                                                                marginPagesDisplayed={1}
                                                                pageRangeDisplayed={2}
                                                                onPageChange={(page) => { this.updateParam('page', page.selected); window.scrollTo(0, 0); }}
                                                                // onPageChange={(page) => { this.props[0].history.push(this.generateSearchLink('page', page.selected)) }}
                                                                containerClassName={'pagination'}
                                                                subContainerClassName={'pages pagination'}
                                                                activeClassName={'active'}
                                                                hrefBuilder={(page) => { return `?page=${page}` }}
                                                                forcePage={Number(this.state.page)}
                                                                // hrefBuilder={(page) => { return this.generateSearchLink('page', page) }}
                                                                //forcePage={this.state.page}
                                                            />

                                                        </Col>

                                                    </Row>

                                                    :

                                                    null
                                            }


                                        </div>



                                    </div>
                                </div>

                            </Col>
                            <Col lg="3" className="nop">
                                {
                                    this.state.home && this.state.home.statistics ?
                                        <RightSidebar
                                            totalVehicles={this.state.home.statistics.totalVehicles}
                                            newMembers={this.state.home.statistics.newMembers}
                                            deliveryLines={this.state.home.statistics.deliveryLines}
                                            warehouses={this.state.home.statistics.warehouses}
                                            shops={this.state.home.statistics.shops}
                                            googlePlayLink={this.state.home.googlePlayLink}
                                            appStoreLink={this.state.home.appStoreLink}
                                            totalEmployess={this.state.home.statistics.totalEmployess}
                                        />
                                        :
                                        null
                                }
                            </Col>
                        </Row>
                    </Container>

                </section>
                {
                    this.state.messageSent ? (
                        <Modal
                            isOpen={this.state.messageSent}
                            toggle={() =>
                                this.setState({
                                    messageSent: !this.state.messageSent,
                                })
                            }
                            style={{ height: '100px' }}
                        >
                            <ModalHeader
                                style={{ display: 'flex', justifyContent: 'center' }}
                            >
                                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginTop: 20, textAlign: 'center' }}>
                                    {this.state.messageSent}
                                    {
                                        this.state.messageSent.indexOf('Vaš zahtjev je uspješno zaprimljen, obrada je u toku, bićete obavješteni.') !== -1 ?
                                            <Isvg src={ok} style={{ marginTop: 20, marginBottom: 20 }} />
                                            :
                                            <Isvg src={xIcon} className='x-icon' style={{ marginTop: 20, marginBottom: 20 }} />
                                    }

                                </div>
                            </ModalHeader>


                        </Modal>
                    ) : null
                }

            </div >
        );
    }
}

export default Page(ShopPage);