import React, { Component } from 'react';
import Link from '../components/link';
import Isvg from 'react-inlinesvg';
import Page from '../containers/page';
import Slider from '../components/slider';
import { Redirect } from 'react-router-dom';


import {
    Container,
    Row,
    Col,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Carousel,
    CarouselItem,
    CarouselControl,
    CarouselIndicators,
    CarouselCaption,
    UncontrolledDropdown,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button
} from 'reactstrap';


import weather from '../assets/weather.svg';
import evidency from '../assets/evidency.png';
import sugestije from '../assets/sugestije.png';
import app from '../assets/app.png';
import b1 from '../assets/b1.png';

import details_naslovna from '../assets/details-naslovna.jpg';


import help_icon from '../assets/help-icon.svg';
import warning_icon from '../assets/warning-icon.svg';
import mail_icon from '../assets/mail-icon.svg';
import chart_icon from '../assets/chart-icon.svg';
import star from '../assets/star.svg';
import like from '../assets/like.svg';
import shape1 from '../assets/svg/shape-1.svg';
import shape2 from '../assets/svg/shape-2.svg';

import close_ico from '../assets/svg/close-ico.svg';

import book_1 from '../assets/book-1.png';
import cartIcon from '../assets/cart-icon.svg';
import moment from 'moment';

import RightSidebar from '../components/rightSidebar';
import { API_ENDPOINT } from '../constants';
import ok from '../assets/svg/ok.svg';
import xIcon from '../assets/svg/x-icon.svg';


class ShopDetailPage extends Component {
    constructor(props) {
        super(props);

        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
        this.goToIndex = this.goToIndex.bind(this);
        this.onExiting = this.onExiting.bind(this);
        this.onExited = this.onExited.bind(this);
        this.state = {
            gallery: [],
            activeIndex: 0,
            latestArticles: [],
            ...props.initialData,
            isCancelModalOpen: false,
            orderId: '',
        };
        this.timerRef = null;
    }

    componentDidMount() {

        if (typeof window !== 'undefined') { window.scrollTo(0, 0); }

        this.get()



    }

    get = () => {
        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match).then((data) => {
                this.setState({
                    ...data
                }, () => this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang)))
            })
        }

        fetch(`https://interni-api.euroexpress.ba/log/new`, {
            method: 'POST',
            headers: {
                'content-type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            },
            body: JSON.stringify({
                path: this.props[0].location.pathname,
            })
        }).then(res => res.json()).then((result) => {

        })

        fetch(`${API_ENDPOINT}/shop/articles/all`, {
            method: 'POST',
            headers: {
                'content-type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            },
            body: JSON.stringify({
                page: this.state.page,
                lang: this.props.lang,
                limit: 3,
                notId: this.props[0].match.params.id
            })
        }).then(res => res.json()).then((result) => {
            if (result && result.items) {
                this.setState({ latestArticles: result.items })
            }

        })
        setInterval(() => {
            this.setState({ aa: new Date().getTime() })
        }, 1000);

    }
    componentDidUpdate(prevProps) {
        if (prevProps[0].location.pathname != this.props[0].location.pathname) {
            if (typeof window !== 'undefined') { window.scrollTo(0, 0); }
            this.get()


            for (let i = 0; i < this.props.loadData.length; i++) {
                this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match).then((data) => {
                    this.setState({
                        ...data
                    })
                })
            }
        }



    }

    onExiting() {
        this.animating = true;
    }

    onExited() {
        this.animating = false;
    }

    next() {
        const images = this.state.data?.images ? this.state.data.images : []

        if (this.animating) return;
        const nextIndex = this.state.activeIndex === images.length - 1 ? 0 : this.state.activeIndex + 1;
        this.setState({ activeIndex: nextIndex });
    }

    previous() {
        const images = this.state.data?.images ? this.state.data.images : []

        if (this.animating) return;
        const nextIndex = this.state.activeIndex === 0 ? images.length - 1 : this.state.activeIndex - 1;
        this.setState({ activeIndex: nextIndex });
    }

    goToIndex(newIndex) {
        if (this.animating) return;
        this.setState({ activeIndex: newIndex });
    }
    onModalOpen = () => {
        this.setState({ isCancelModalOpen: true });

        this.timerRef = setTimeout(() => {
            this.onModalClose();
        }, 10000);
    };

    onModalClose = () => {
        this.setState({ isCancelModalOpen: false, orderId: '' });

        if (this.timerRef) {
            clearTimeout(this.timerRef);
            this.timerRef = null;
        }
    };
    cancelOrder = () => {
        fetch(`${API_ENDPOINT}/shop/delete/order/${this.state.orderId}`, {
            method: 'DELETE',
            headers: {
                'content-type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            },}).then(this.onModalClose)
    }
    buyArticle = (articleId) => {
        if (articleId) {

            fetch(`${API_ENDPOINT}/shop/buy/article`, {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                },
                body: JSON.stringify({
                    article: articleId
                })
            }).then(res => res.json()).then((result) => {
                if (result) {
                    if (result.error) {
                        this.setState({ messageSent: result.error })
                    } else {
                        this.setState({ orderId: result.orderId})
                        this.onModalOpen()
                        this.props.verifyUser()
                    }
                }

            })

        }
    }

    render() {
        const images = this.state.data?.images ? this.state.data.images : []

        const { activeIndex } = this.state;
        const slides = images.map((item) => {
            return (
                <CarouselItem
                    tag="div"
                    key={item}
                    onExiting={this.onExiting}
                    onExited={this.onExited}
                >
                    <div className="lightbox-item">
                        <img src={API_ENDPOINT + item} />
                    </div>

                </CarouselItem>
            );
        });

        // let showBuyButton = false;
        // let hours48 = 60 * 60 * 48;
        // if (this.state.data && this.state.data.activeTs && (Math.floor(new Date().getTime() / 1000) - this.state.data.activeTs) > hours48) {
        //     showBuyButton = true;
        // }
        let showBuyButton = this.state.data && this.state.data.showBuyButton;

        return (
            <div>
                {
                    typeof localStorage !== 'undefined' && !localStorage.token ? <Redirect to='/prijava' /> : null
                }
                <img src={shape1} className="shape-1" />
                <img src={shape2} className="shape-2" />
                <section id="content">
                    <Container>
                        <Row>
                            <Col lg="9" className="nop">
                                <div className="content l-0 r-fix pd0">
                                    <Col lg="12">
                                        <div className='web-shop-detail-page-wrap'>
                                            <div className='web-shop-detail-page-intro'>
                                                <div className='images-wrap' ref={node => this.imagesDivRef = node}>
                                                    {
                                                        images && images.length > 0 ?

                                                            <div className="foto-gallery-wrap" style={{ margin: 0 }}>

                                                                {/* <h3>Galerija slika:</h3> */}
                                                                <div className="foto-gallery">
                                                                    {
                                                                        images.map((item, idx) => {
                                                                            return (
                                                                                idx == 0 ?
                                                                                    <div className="gallery-image1" onClick={() => {
                                                                                        let index = idx;
                                                                                        this.setState({ lightbox: true, activeIndex: index })
                                                                                    }}
                                                                                        key={idx}>
                                                                                        <img src={API_ENDPOINT + item} />
                                                                                    </div>
                                                                                    :
                                                                                    null

                                                                            )
                                                                        })
                                                                    }
                                                                    <div className="gallery-image2-wrap">

                                                                        {
                                                                            images.map((item, idx) => {
                                                                                return (
                                                                                    idx > 0 && idx < 4 ?
                                                                                        <div className={idx == 3 && images.length > 4 ? "gallery-image3 overlay" : "gallery-image3"} onClick={() => {
                                                                                            let index = idx;
                                                                                            this.setState({ lightbox: true, activeIndex: index })
                                                                                        }} key={idx}>
                                                                                            <img src={API_ENDPOINT + item} />
                                                                                            <div className="other-images">
                                                                                                {
                                                                                                    idx == 3 && images.length > 4 ?
                                                                                                        <h4>+{images.length - 4}</h4>
                                                                                                        :
                                                                                                        null
                                                                                                }
                                                                                            </div>
                                                                                        </div>
                                                                                        :
                                                                                        null

                                                                                )
                                                                            })
                                                                        }
                                                                    </div>
                                                                </div>

                                                            </div>

                                                            :
                                                            null
                                                    }

                                                </div>
                                                <div className='article-info-wrap' style={{ minHeight: images.length > 1 ? 400 : 290 }}>
                                                    <div>
                                                        <h6>{this.state.data && this.state.data.categoryName}</h6>
                                                        <h1>{this.state.data && this.state.data.name}</h1>

                                                        <h6 style={{ fontSize: 16 }}>KRATKI OPIS</h6>

                                                        {
                                                            this.state.data && this.state.data.shortDescription && this.state.data.checkIntro ?
                                                                <>
                                                                    <p>{this.state.data.shortDescription.split(",")[0] + ","}
                                                                        <br /><br />
                                                                        {this.state.data.shortDescription.slice(this.state.data.shortDescription.split(",")[0].length + 1)}</p>
                                                                </>
                                                                : this.state.data && this.state.data.shortDescription && !this.state.data.checkIntro ?
                                                                    <p>{this.state.data.shortDescription}</p>
                                                                    :
                                                                    null

                                                        }

                                                    </div>
                                                    <div className='price-wrap'>
                                                        {this.state.data && this.state.data.originalPrice ? <p>Prosječna tržišna vrijednost:</p> : null}
                                                        {this.state.data && this.state.data.originalPrice ? <span>{this.props.formatPrice(this.state.data.originalPrice)}</span> : null}
                                                        <h6>{this.state.data && this.state.data.price ? this.props.formatPrice(this.state.data.price) : null}</h6>
                                                        {this.state.data && this.state.data.transportPrice ? <p>Cijena transporta: <span style={{ textDecoration: 'none', fontSize: 14 }}>{this.props.formatPrice(this.state.data.transportPrice)}</span> </p> : null}
                                                        <div className='buy-article-wrap'>
                                                            <div className="shop-article-buy">
                                                                <button isabled={!showBuyButton} className={!showBuyButton ? 'hide-button' : ''} onClick={(e) => {
                                                                    if (showBuyButton) {
                                                                        e.stopPropagation()
                                                                        this.buyArticle(this.state.data._id)
                                                                    }
                                                                }}>
                                                                    <Isvg src={cartIcon} />
                                                                    Kupi sada
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='article-content'>
                                                <h6 style={{ fontSize: 16 }}>Detaljne informacije</h6>
                                                {
                                                    this.state.data ?
                                                        <div dangerouslySetInnerHTML={{ __html: this.state.data.description }}></div>
                                                        :
                                                        null
                                                }
                                                {
                                                    this.state.data && this.state.data.quantity ?
                                                        <p style={{ marginTop: 5}}>Količina: {this.state.data.quantity}</p>
                                                        :
                                                        null
                                                }
                                            </div>
                                        </div>

                                    </Col>
                                    <Col lg="12">
                                        <Row className="similar-articles mb-25">
                                            <Col lg="12">
                                                <div className="other-news-title">
                                                    {
                                                        this.state.latestArticles.length > 0 ?
                                                            <div className="flex">
                                                                <h5 className="section-title">Novi artikli</h5>
                                                            </div>
                                                            :
                                                            null
                                                    }

                                                </div>
                                            </Col>
                                            {
                                                this.state.latestArticles && this.state.latestArticles.map((item, idx) => {
                                                    let showBuyButton = false;
                                                    let hours48 = 60 * 60 * 48;
                                                    if (item && item.activeTs && (Math.floor(new Date().getTime() / 1000) - item.activeTs) > hours48) {
                                                        showBuyButton = true;
                                                    }
                                                    return (
                                                        <Col lg="4" className="shop-article-wrap">
                                                            <div className="shop-article" onClick={() => {
                                                                this.props[0].history.push(`/interni-shop/${item.categoryLink}/${item._id}`)
                                                            }}>
                                                                <div className="shop-article-image">
                                                                    <img src={API_ENDPOINT + (item.images && item.images[0])} />
                                                                </div>
                                                                <div className="shop-article-category">
                                                                    {item.categoryName}
                                                                </div>
                                                                <div className="shop-article-name">
                                                                    {typeof window != 'undefined' && window.innerWidth < 1024 ?
                                                                        item.name.length > 35 ?
                                                                            <h3>{item.name.substring(0, 35)}...</h3>
                                                                            :
                                                                            <h3>{item.name}</h3>
                                                                        :
                                                                        item.name.length > 35 ?
                                                                            <h3>{item.name.substring(0, 35)}...</h3>
                                                                            :
                                                                            <h3>{item.name}</h3>
                                                                    }
                                                                </div>
                                                                <div className="shop-article-price">
                                                                    {this.props.formatPrice(item.price)}
                                                                </div>
                                                                <div className="shop-article-buy">
                                                                    <button disabled={!showBuyButton} className={!showBuyButton ? 'hide-button' : ''} onClick={(e) => {
                                                                        if (showBuyButton) {
                                                                            e.stopPropagation()
                                                                            this.buyArticle(item._id)
                                                                        }
                                                                    }}>
                                                                        <Isvg src={cartIcon} />
                                                                        Kupi sada
                                                                    </button>
                                                                </div>
                                                            </div>

                                                        </Col>
                                                    )
                                                })
                                            }
                                        </Row>
                                    </Col>

                                </div>
                            </Col>
                            <Col lg="3" className="nop">
                                {
                                    this.state.home && this.state.home.statistics ?
                                        <RightSidebar
                                            totalVehicles={this.state.home.statistics.totalVehicles}
                                            newMembers={this.state.home.statistics.newMembers}
                                            deliveryLines={this.state.home.statistics.deliveryLines}
                                            totalEmployess={this.state.home.statistics.totalEmployess}
                                            warehouses={this.state.home.statistics.warehouses}
                                            shops={this.state.home.statistics.shops}
                                            googlePlayLink={this.state.home.googlePlayLink}
                                            appStoreLink={this.state.home.appStoreLink}
                                        />
                                        :
                                        null
                                }
                            </Col>
                        </Row>
                    </Container>
                    {this.state.lightbox ?
                        <div className="lightbox">
                            <div className="close" onClick={() => this.setState({ lightbox: null })}>
                                <Isvg src={close_ico} />
                            </div>
                            <div className="photo-num">
                                <h5>{Number(this.state.activeIndex) + 1} / {images.length}</h5>
                            </div>

                            <Carousel
                                activeIndex={activeIndex}
                                next={this.next}
                                previous={this.previous}
                                autoPlay={null}
                            >
                                {slides}
                                <CarouselControl direction="prev" directionText="Previous" onClickHandler={this.previous} />
                                <CarouselControl direction="next" directionText="Next" onClickHandler={this.next} />
                            </Carousel>

                        </div>
                        : null
                    }
                </section>

                {this.state.messageSent ? (
                    <Modal
                        isOpen={this.state.messageSent}
                        toggle={() =>
                            this.setState({
                                messageSent: !this.state.messageSent,
                            })
                        }
                        style={{ height: '100px' }}
                    >
                        <ModalHeader
                            style={{ display: 'flex', justifyContent: 'center' }}
                        >
                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginTop: 20, textAlign: 'center' }}>
                                {this.state.messageSent}
                                {
                                    this.state.messageSent.indexOf('Vaš zahtjev je uspješno zaprimljen, obrada je u toku, bićete obavješteni.') !== -1 ?
                                        <Isvg src={ok} style={{ marginTop: 20, marginBottom: 20 }} />
                                        :
                                        <Isvg src={xIcon} className='x-icon' style={{ marginTop: 20, marginBottom: 20 }} />
                                }

                            </div>
                        </ModalHeader>


                    </Modal>
                ) : null}
                {
                    this.state.isCancelModalOpen ? (
                        <Modal isOpen={this.state.isCancelModalOpen} backdrop={true} toggle={() => this.onModalClose()} className="cancel-order-modal">
                            <ModalBody>
                                <button className={'xButton'} onClick={() => this.onModalClose()}>x</button>
                                <div><span>Poštovani,</span>
                                    <span>ukoliko je ova kupovina izvršena greškom,</span>
                                    <span>možete je jednostavno opozvati klikom na dugme ispod.</span>
                                </div>
                                <button className={'cancelButton'} onClick={() => this.cancelOrder()}>Opozovi kupovinu</button>

                            </ModalBody>
                        </Modal>
                    ) : null
                }

            </div>
        );
    }
}

export default Page(ShopDetailPage);