

import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import Isvg from 'react-inlinesvg';

import {
    Container,
    Row,
    Col,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,

} from 'reactstrap';



import weather from '../assets/weather.svg';
import evidency from '../assets/evidency.png';
import sugestije from '../assets/sugestije.png';
import kodeks from '../assets/kodeks.png';
import app from '../assets/app.png';
import b1 from '../assets/b1.png';
import b2 from '../assets/b2.png';

import help_icon from '../assets/help-icon.svg';
import warning_icon from '../assets/warning-icon.svg';
import mail_icon from '../assets/mail-icon.svg';
import chart_icon from '../assets/chart-icon.svg';
import star from '../assets/star.svg';
import like from '../assets/like.svg';

import book_1 from '../assets/book-1.png';
import team_member from '../assets/team-member.png';

import { API_ENDPOINT } from '../constants';


import profile from '../assets/profile.png';
import bars from '../assets/bars.png';


export class RightSidebar extends Component {
    constructor(props) {
        super(props);

        this.state = {
            weatherId: 0,
            cities: [
                '3204541',
                '3204186',
                '3201984',
                '3188893',
                '3191281',
                '3188582',
                '3194828',
                '3204222',
                '3192409',
                '3199779',
                '3203521',
                '3186423',
                '3186573',
                '3203099',
                '3200837',
                '3187609',
            ]
        };
    }

    componentDidMount() {

        // Banja Luka - cityID: 3204541
        this.getWeather()
    }
    getWeather = () => {
        fetch('https://api.openweathermap.org/data/2.5/weather?id=' + this.state.cities[this.state.weatherId] + '&appid=ead872841a3f922859ce83fabb5b5e39')
            .then(res => res.json()).then((result) => {
                // console.log(result);
                let icon_path = `https://openweathermap.org/img/w/` + result.weather[0].icon + `.png`;
                this.setState({
                    city: result.name,
                    temp: Math.round(result.main.temp - 273),
                    weatherIcon: icon_path
                })
            })
            .catch(function () {
            });
    }
    decWeatherId = () => {
        if (this.state.weatherId > 0) {
            this.setState({ weatherId: this.state.weatherId - 1 }, () => this.getWeather())
        } else {
            this.setState({ weatherId: this.state.cities.length - 1 }, () => this.getWeather())
        }

    }
    incWeatherId = () => {
        if (this.state.weatherId < this.state.cities.length - 1) {
            this.setState({ weatherId: this.state.weatherId + 1 }, () => this.getWeather())
        } else {
            this.setState({ weatherId: 0 }, () => this.getWeather())
        }

    }

    render() {
        return (
            <div className="sidebar">
                <div className="sidebar-content">
                    <div className="weather">
                        <h6>Vremenska prognoza</h6>
                        <div className="weather-content">
                            {
                                this.state.weatherIcon ?
                                    <img src={this.state.weatherIcon} alt="" />
                                    :
                                    null

                            }

                            <div className="weather-info">
                                <span className="temperature">{this.state.temp && this.state.temp} &deg;C </span> <br />
                                <div className="city-wrap">
                                    <span className="city">{this.state.city && this.state.city}</span>
                                    <div className="next-btn-wrap">
                                        <button className="next-btn" onClick={() => this.decWeatherId()}>&lt;</button>
                                        <button className="next-btn" onClick={() => this.incWeatherId()}>&gt;</button>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="business d-flex">
                        <img src={kodeks}/>
                        <div className="text-box">
                            <a href="https://interni-api.euroexpress.ba/uploads/13b4948d-3276-4eb3-bf6f-fc401d9eac56.pdf">KODEKS
                                POSLOVNOG PONAŠANJA</a>
                        </div>

                    </div>
                    <div className="evidency">
                    <div className="evidency-content">
                            <img src={evidency} alt="" />
                            <div className="evidency-text">
                                <h6>Pregled
                                odsutnih
                    radnika</h6>
                                <Link to="/kalendar">Detaljnije</Link>
                            </div>
                        </div>
                    </div>
                    <div className="facts">
                        <h6>EuroExpress brza pošta danas</h6>
                        <div className="facts-row">
                            <div className="fact">
                                <h5>{this.props && this.props.totalEmployess}</h5>
                                <span>Ukupno zaposlenih</span>
                            </div>
                            <div className="fact">
                                <h5>{this.props && this.props.totalVehicles}</h5>
                                <span>Ukupno vozila</span>
                            </div>


                        </div>
                        <div className="facts-row">

                            <div className="fact">
                                <h5>{this.props && this.props.deliveryLines}</h5>
                                <span>Dostavnih linija</span>
                            </div>
                            <div className="fact">
                                <h5>{this.props && this.props.newMembers}</h5>
                                <span>Novih kolega u prethodnoj godini</span>
                            </div>

                        </div>
                        <div className="facts-row">
                            <div className="fact">
                                <h5>{this.props && this.props.warehouses}</h5>
                                <span>Distributivnih centara</span>
                            </div>
                            <div className="fact">
                                <h5>{this.props && this.props.shops}</h5>
                                <span>Paket Shop-ova</span>
                            </div>

                        </div>
                    </div>
                    <div className="sugestion-widget">
                        <h6>KUTIJA ZA PRIJEDLOGE</h6>
                        <img src={sugestije} alt="sugestion" />
                        <Link to="/kontakt"><h5 style={{textAlign: 'center'}}>Kutak za ideje</h5></Link>

                    </div>
                    <div className="app">
                        <h6>NAŠE APLIKACIJE</h6>
                        <div className="app-content">
                            <div className="app-image">
                                <img src={app} alt="" />
                            </div>
                            <div className="app-buttons">
                                <a href={this.props && this.props.googlePlayLink} target="_blank"><img src={b1} alt="btn" /></a>
                                <a href={this.props && this.props.appStoreLink} target="_blank"><img src={b2} alt="btn" /></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default RightSidebar;
