import React, { Component } from 'react';
import Link from '../components/link';

import Isvg from 'react-inlinesvg';
import Page from '../containers/page';

import {
    Container,
    Row,
    Col,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Carousel,
    CarouselItem,
    CarouselControl,
    CarouselIndicators,
    CarouselCaption,
    UncontrolledDropdown,
    Modal, ModalHeader, ModalBody, ModalFooter, Button
    ,Input
} from 'reactstrap';


import moment from 'moment';

import { Player, Controls } from "@lottiefiles/react-lottie-player";

import no_content from '../assets/images/no_content.png';

import arrow_left from '../assets/arrow-left.svg';
import list_1 from '../assets/list-1.svg';
import list_2 from '../assets/list-2.svg';
import arrow_right from '../assets/arrow-right.svg';
import weather from '../assets/weather.svg';
import evidency from '../assets/evidency.png';
import sugestije from '../assets/sugestije.png';
import app from '../assets/app.png';
import b1 from '../assets/b1.png';
import shape1 from '../assets/svg/shape-1.svg';
import shape2 from '../assets/svg/shape-2.svg';

import details_naslovna from '../assets/details-naslovna.jpg';

import ReactPaginate from 'react-paginate';
import RightSidebar from '../components/rightSidebar';
import { API_ENDPOINT } from '../constants';

import { Redirect } from 'react-router-dom';
import error from "../../../admin/src/assets/images/error.png";
import pdf from "../assets/pdf.png";
import xx from "../assets/xx.png";
import word from "../assets/word.png";
import powerPoint from "../assets/powerPoint.png";
import laptop from "../assets/images/laptop.png";


var striptags = require('striptags');

class BlogPage extends Component {
    constructor(props) {
        super(props);
        this.updateStateFromSearch = this.updateStateFromSearch.bind(this);
        this.updateParam = this.updateParam.bind(this);
        this.get = this.get.bind(this);

        this.state = {
            promotedProjects: [],
            page: 0,
            view: 0,
            ...props.initialData,
            isFolderLoginModalOpen: false,
            folderPin:'',
            errorMessage:'',
            selectedFolder:{},
            showInput: true,
        };
    }

    updateStateFromSearch(callback) {
        let broken = this.props[0].location.search.split('?').pop().split('&');
        let params = { page: 0 };
        for (let i = 0; i < broken.length; i++) {
            if (broken[i].indexOf('=') !== -1) {
                params[broken[i].split('=')[0]] = broken[i].split('=')[1];
            }
        }

        this.setState({
            category: null,
            tag: null,
            items: []
        }, () => {
            this.setState(params, callback);

        })

    }


    updateParam(name, value) {

        let broken = this.props[0].location.search.split('?').pop().split('&');
        let params = {};
        for (let i = 0; i < broken.length; i++) {
            if (broken[i].indexOf('=') !== -1) {
                params[broken[i].split('=')[0]] = broken[i].split('=')[1];
            }
        }

        params[name] = value;

        let paramsArr = [];
        for (var key in params) {
            if (params.hasOwnProperty(key) && params[key]) {

                paramsArr.push(key + "=" + params[key]);
            }
        }



        let search = '?' + paramsArr.join('&');


        this.props[0].history.push(this.props[0].location.pathname + search);
        //console.log(search);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps[0].location.search != this.props[0].location.search) {
            this.updateStateFromSearch(() => {
                this.get()
            });
        }
        if (prevProps[0].location.pathname != this.props[0].location.pathname) {
            this.get()
        }
    }

    componentDidMount() {

        if (typeof window !== 'undefined') { window.scrollTo(0, 0); }


        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match).then((data) => {
                this.setState({
                    ...data
                })
            })
        }


        fetch(`${API_ENDPOINT}/log/new`, {
            method: 'POST',
            headers: {
                'content-type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            },
            body: JSON.stringify({
                path: this.props[0].location.pathname,
            })
        }).then(res => res.json()).then((result) => {

        })




        this.updateStateFromSearch(this.get);


    }

    async folderClickHandler(folder){
        this.setState({selectedFolder: folder, showInput: true});
        const folderToken = sessionStorage.getItem('folderToken');
        if(!folder.pin){
            this.setState({showInput: false});
            this.handleFolderLogin(folder);
        }else
        if (!folderToken){
               this.setState({isFolderLoginModalOpen: true});
        }else{
                const response = await fetch(`${API_ENDPOINT}/folders/verify/${folder.folderAlias}/${this.props[0].match.params.category}/${this.props[0].match.params.submenu}`, {
                    method: 'POST',
                    headers: {
                        'content-type': 'application/json',
                        'Authorization': `Bearer ${localStorage.getItem('token')}`
                    },
                    body: JSON.stringify({
                        folderToken: folderToken,
                    })
                })
            if (!response.ok){
                this.setState({isFolderLoginModalOpen: true})
            }else{
                this.props[0].history.push(`${typeof window !== 'undefined' ? window.location.pathname : ''}/folder/${folder.folderAlias}`);
            }
        }
    }

    async handleFolderLogin(folder) {
        const response = await fetch(`${API_ENDPOINT}/folders/login/${folder._id}`, {
            method: 'POST',
            headers: {
                'content-type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            },
            body: JSON.stringify({
                pin: this.state.folderPin,
            })
        });
        if (response.ok) {
            const result = await response.json();
            sessionStorage.setItem('folderToken', result.folderToken);
            this.setState({isFolderLoginModalOpen: false});
            this.props[0].history.push(`${typeof window !== 'undefined' ? window.location.pathname : ''}/folder/${folder.folderAlias}`);
        } else {
            this.setState({errorMessage:'Nemate pristup folderu', isFolderLoginModalOpen: true});
        }
    }
    onModalClose(){
        this.setState({isFolderLoginModalOpen: false, folderPin:'', errorMessage:''});
    }
    get() {
        if (this.props[0].location.pathname === "/arhiva-novosti") {
            fetch(`${API_ENDPOINT}/articlesall`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    page: this.state.page,
                    lang: this.props.lang
                })
            })
                .then(res => res.json())
                .then(result => {
                    for (let i = 0; i < result.items.length; i++) {
                        result.items[i].dateTS = new Date(result.items[i].date + " " + result.items[i].time).getTime() / 1000;
                    }
                    this.setState({
                        items: result.items,
                        total: result.total
                    });
                });
        } else if (this.props[0].match.params.folder) {
            const folderToken = sessionStorage.getItem('folderToken');
            fetch(`${API_ENDPOINT}/folders/verify/${this.props[0].match.params.folder}/${this.props[0].match.params.category}/${this.props[0].match.params.submenu}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    folderToken: folderToken
                })
            })
                .then(res => {
                    if (res.status === 401) {
                        throw new Error('Unauthorized');
                    }
                    if (res.status !== 200) {
                        throw new Error(`Unexpected status code: ${res.status}`);
                    }
                })
                .then(() => {
                    return fetch(`${API_ENDPOINT}/articles/folder/${this.props[0].match.params.folder}/${this.props[0].match.params.category}/${this.props[0].match.params.submenu}`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({
                            page: this.state.page,
                            lang: this.props.lang
                        })
                    });
                })
                .then(res => res.json())
                .then(result => {
                    for (let i = 0; i < result.items.length; i++) {
                        result.items[i].dateTS = new Date(result.items[i].date + " " + result.items[i].time).getTime() / 1000;
                    }
                    this.setState({
                        folders: [],
                        items: result.items,
                        total: result.total,
                        submenuName: result.submenuName,
                        folderName: result.folderName,
                    }, () => this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang)));
                })                .then(() => {
                return fetch(`${API_ENDPOINT}/documents/${this.props[0].match.params.folder}/${this.props[0].match.params.category}/${this.props[0].match.params.submenu}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                });
            })
                .then(res => res.json())
                .then(result => {
                    this.setState({ documents: result})
                })
                .catch(error => {
                    if (error.message === 'Unauthorized') {
                        const currentPath = typeof window !== 'undefined' ? window.location.pathname : '';
                        const pathSegments = currentPath.split('/').filter(segment => segment);
                        const newPath = `/${pathSegments.slice(0, -2).join('/')}`;
                        this.props[0].history.push(newPath);
                    } else {
                        console.error('Error:', error);
                    }
                });
        } else {
            fetch(`${API_ENDPOINT}/articles/${this.props[0].match.params.category}/${this.props[0].match.params.submenu}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    page: this.state.page,
                    lang: this.props.lang
                })
            })
                .then(res => res.json())
                .then(result => {
                    for (let i = 0; i < result.items.length; i++) {
                        result.items[i].dateTS = new Date(result.items[i].date + " " + result.items[i].time).getTime() / 1000;
                    }
                    this.setState({
                        items: result.items,
                        total: result.total,
                        submenuName: result.submenuName,
                        folderName: ''
                    }, () => this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang)));
                });

            fetch(`${API_ENDPOINT}/folders/${this.props[0].match.params.category}/${this.props[0].match.params.submenu}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            })
                .then(res => res.json())
                .then(data => {
                    this.setState({
                        folders: data
                    });
                });
        }
    }
    downloadLog = (item) => {


        fetch(`https://interni-api.euroexpress.ba/log/new`, {
            method: 'POST',
            headers: {
                'content-type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            },
            body: JSON.stringify({
                path: item.icon,
                type: 'download',
                docName: item.name
            })
        }).then(res => res.json()).then((result) => {

        })
    }



    render() {
        return (
            <div>
                {
                    typeof localStorage !== 'undefined' && !localStorage.token ? <Redirect to='/prijava' /> : null
                }
                <img src={shape1} className="shape-1" />
                <img src={shape2} className="shape-2" />

                <section id="content">
                    <Container>
                            <Modal isOpen={this.state.isFolderLoginModalOpen} backdrop={true} toggle={() => this.onModalClose()} className="folder-login-modal">
                                <ModalBody>
                                    {this.state.showInput &&<Input placeholder={'Unesite pin za pristup folderu'} onChange={(e)=>{this.setState({folderPin: e.target.value})}}/>}
                                    {this.state.errorMessage && <span className={"b-active"}>{this.state.errorMessage}</span> }
                                </ModalBody>
                                <ModalFooter>
                                    <Button type="submit" color="danger" onClick={() => this.onModalClose() }>Zatvori</Button>{' '}
                                    {this.state.showInput && <Button type="submit" color="primary" onClick={() => {
                                        this.handleFolderLogin(this.state.selectedFolder)
                                    }}>Potvrdi</Button>}
                                </ModalFooter>
                            </Modal>
                        <Row>
                            <Col lg="9" className="nop">
                                <div className="content l-0 r-fix pd0">
                                    <div className="content-container">
                                        <div className="news-list-title">
                                            <div className="title-content">
                                                <h5 className="section-title">{this.state.blogLatest && this.state.blogLatest.category}</h5>
                                                <div className="breadcumb">
                                                    {
                                                        this.props[0].location.pathname == "/arhiva-novosti" ?
                                                            <span>
                                                                <Link to="/">Početna</Link>/
                                                                <a className="b-active">Arhiva novosti</a>
                                                            </span>
                                                            :
                                                            <span>
                                                                <Link to="/">Početna</Link>&nbsp;/&nbsp;<a>{this.state.blogLatest && this.state.blogLatest.category}</a>&nbsp;/&nbsp;{!this.state.folderName ? (
                                                                <>
                                                                    <a className={`b-active`}>{this.state.submenuName && this.state.submenuName}</a>
                                                                </>
                                                            )  : (
                                                                <Link to={`../../${this.props[0].match.params.submenu}`}>{this.state.submenuName && this.state.submenuName}</Link>
                                                            )}{this.state.folderName && (
                                                                <>
                                                                    &nbsp;/&nbsp;<a className="b-active">{this.state.folderName}</a>
                                                                </>
                                                            )}

                                                            </span>

                                                    }
                                                </div>
                                            </div>
                                            {/*
                                            <div className="grid">
                                                <div className={!this.state.view ? "grid-container grid-container-1 grid-active" : "grid-container grid-container-1"} onClick={() => this.setState({ view: 0 })}>
                                                    <img src={list_1} alt="grid icon" className="list-1" />
                                                </div>
                                                <div className={this.state.view ? "grid-container grid-container-2 grid-active" : "grid-container grid-container-2"} onClick={() => this.setState({ view: 1 })}>
                                                    <img src={list_2} alt="grid icon" className="list-2" />
                                                </div>
                                            </div>
                                            */}
                                        </div>

                                        <div className="content-1">
                                            {
                                                this.state.folders?.length?
                                                    <Row className="nop">
                                                        {
                                                            this.state.folders.map((item, idx) => {
                                                                return (
                                                                    <Col lg="3" >
                                                                        <div className="shop-categories-wrap" onClick={() => {
                                                                            this.folderClickHandler(item)
                                                                        }}>
                                                                            {
                                                                                item.icon ?
                                                                                    <div className="shop-categories-icon">
                                                                                        <img src={API_ENDPOINT + item.icon} />
                                                                                    </div>
                                                                                    :
                                                                                    null
                                                                            }
                                                                            <div className="shop-categories-name">
                                                                                {item.folder}
                                                                            </div>
                                                                        </div>
                                                                    </Col>
                                                                )
                                                            })
                                                        }
                                                    </Row>
                                                    :
                                                    null

                                            }

                                            <Row className="nop">
                                                {
                                                    (!this.state.items || !this.state.items.length) && (!this.state.documents || !this.state.documents.length) ?
                                                        <div className="no-content" >
                                                            <Player
                                                                autoplay={true}
                                                                loop={true}
                                                                src="/lf30_editor_w4hitwma.json"
                                                                style={{ width: "60%" }}
                                                            ></Player>
                                                            <p>Stranica u izradi...</p>
                                                        </div>
                                                        :
                                                        null
                                                }
                                                {
                                                    this.state.documents && this.state.documents.length ? <>
                                                    <Col lg={12}>
                                                    <div className="blue-bg" style={{ paddingRight: 0 }}>
                                                        <div className="content-container document-container">
                                                            <div className="documents">
                                                                <div>
                                                                    <h1>Preuzmite željeni dokument</h1>
                                                                    <p>Za
                                                                        preuzimanje  dokumenta kliknite na ikonicu ili na naslov
                                                                        dokumenta.</p>

                                                                    <p>Preuzimanje dokumenta će biti automatski
                                                                        pokrenuto.</p>
                                                                </div>
                                                                <img src={laptop}/>
                                                            </div>
                                                            <div className="documents-files-container">
                                                                <div className="document-divider">
                                                                    <h3 className="document-subtitle-1">Dokumenti</h3>
                                                                </div>
                                                                <Row>
                                                                    {
                                                                        this.state.documents.map((item, idx) => {
                                                                            return (
                                                                                <Col lg="6">
                                                                                    <a href={API_ENDPOINT + item.icon}
                                                                                       download={item.name}>
                                                                                        <div className="document"
                                                                                             onClick={() => this.downloadLog(item)}>
                                                                                            <img
                                                                                                src={item.format == 'pdf' ? pdf : item.format == 'exel' ? xx : item.format == 'word' ? word : item.format == 'powerPoint' ? powerPoint : null}
                                                                                                alt=""/>

                                                                                            <h6>{item.name}</h6>

                                                                                        </div>
                                                                                    </a>
                                                                                </Col>
                                                                            )
                                                                        })
                                                                    }
                                                                </Row>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    </Col>
                                                        </>
                                                        : null
                                                }
                                                {
                                                    this.state.items && this.state.items.map((item, idx) => {
                                                        return (
                                                            <Col lg="12" className="article-grid-list">
                                                                {/*<Link to={`/${item.menuCategory}/novosti/${item.categoryLink}/${item._id}`}>*/}
                                                                    <Link to={`${this.props[0].location.pathname}/${item._id}`}>
                                                                    <article className="article">
                                                                        <div className="article-header bg-1">
                                                                            <div className="image-wrap">
                                                                                <img src={API_ENDPOINT + item.image} />
                                                                            </div>
                                                                        </div>
                                                                        <div className="article-body">
                                                                            <a>{item.categoryName}</a>
                                                                            {typeof window != 'undefined' && window.innerWidth < 1024 ?
                                                                                item?.name?.length > 35 ?
                                                                                    <h3>{item.name.substring(0, 35)}...</h3>
                                                                                    :
                                                                                    <h3>{item.name}</h3>
                                                                                :
                                                                                item?.name?.length > 50 ?
                                                                                    <h3>{item.name.substring(0, 50)}...</h3>
                                                                                    :
                                                                                    <h3>{item.name}</h3>
                                                                            }
                                                                            <span>{item && item.dateTS ? this.props.translateMonthBlog(item.dateTS) : null}</span>
                                                                            {typeof window != 'undefined' && window.innerWidth < 1024 ?
                                                                                item?.shortDescription?.length > 40 ?
                                                                                    <p>{item.shortDescription.substring(0, 40)}...</p>
                                                                                    :
                                                                                    <p>{item.shortDescription}</p>
                                                                                :
                                                                                item?.shortDescription?.length > 110 ?
                                                                                    <p>{item.shortDescription.substring(0, 110)}...</p>
                                                                                    :
                                                                                    <p>{item.shortDescription}</p>}
                                                                        </div>
                                                                    </article>
                                                                </Link>
                                                            </Col>
                                                        )
                                                    })
                                                }

                                            </Row>
                                            {
                                                this.state.total / 5 > 1 ?
                                                    <Row>
                                                        <Col lg="12">
                                                            <ReactPaginate
                                                                previousLabel={''.translate(this.props.lang)}
                                                                nextLabel={''.translate(this.props.lang)}
                                                                breakLabel={'...'}
                                                                breakClassName={'break-me'}
                                                                pageCount={this.state.total / 5}
                                                                marginPagesDisplayed={1}
                                                                pageRangeDisplayed={2}
                                                                onPageChange={(page) => { this.updateParam('page', page.selected); window.scrollTo(0, 0); }}
                                                                // onPageChange={(page) => { this.props[0].history.push(this.generateSearchLink('page', page.selected)) }}
                                                                containerClassName={'pagination'}
                                                                subContainerClassName={'pages pagination'}
                                                                activeClassName={'active'}
                                                                hrefBuilder={(page) => { return `?page=${page}` }}
                                                                forcePage={Number(this.state.page)}
                                                            // hrefBuilder={(page) => { return this.generateSearchLink('page', page) }}
                                                            //forcePage={this.state.page}
                                                            />

                                                        </Col>

                                                    </Row>

                                                    :

                                                    null
                                            }


                                        </div>



                                    </div>
                                </div>

                            </Col>
                            <Col lg="3" className="nop">
                                {
                                    this.state.home && this.state.home.statistics ?
                                        <RightSidebar
                                            totalVehicles={this.state.home.statistics.totalVehicles}
                                            newMembers={this.state.home.statistics.newMembers}
                                            deliveryLines={this.state.home.statistics.deliveryLines}
                                            warehouses={this.state.home.statistics.warehouses}
                                            shops={this.state.home.statistics.shops}
                                            googlePlayLink={this.state.home.googlePlayLink}
                                            appStoreLink={this.state.home.appStoreLink}
                                            totalEmployess={this.state.home.statistics.totalEmployess}
                                        />
                                        :
                                        null
                                }
                            </Col>
                        </Row>
                    </Container>

                </section>

            </div>
        );
    }
}

export default Page(BlogPage);